<template>
  <b-card>

    <b-row>

      <!-- Application Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Application Avatar & Action Buttons -->
        <div class="d-flex mt-2 justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h2 class="mb-0">
                <img
                  width="48"
                  :src="require(`@/assets/images/logos/${scenarioData.app_name}.webp`)"
                >
                {{ scenarioData.title }}
              </h2>
            </div>
            <div class="mb-1">
              <span class="text-muted">
                Scenario Action
              </span>
              <p class="mb-0">
                {{ scenarioData.action }}
              </p>
            </div>
            <div class="mb-1">
              <span class="text-muted">
                Scenario Overview
              </span>
              <p class="mb-0">
                {{ scenarioData.overview }}
              </p>
            </div>
            <div class="mb-1">
              <span class="text-muted">
                Created At
              </span>
              <p class="mb-0">
                {{ new Date(scenarioData.date) }}
              </p>
            </div>
            <div class="d-flex flex-wrap" v-if="0">
              <b-button
                :to="{ name: 'apps-applications-edit', params: { id: scenarioData.id } }"
                variant="outline-warning"
              >
                Disable
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <b-col
        xl="2"
        class="pt-4 text-center"
      >
        <div>
          <span class="text-muted">
            Root Cause Issue
          </span>
          <h3 class="mb-0">
            {{ scenarioData.root.join(", ") }}
          </h3>
        </div>
        <div class="mt-2">
          <span class="text-muted">
            Potential Impact
          </span>
          <h3 class="mb-0">
            {{ scenarioData.impact.join(", ") }}
          </h3>
        </div>
      </b-col>
      <b-col
        xl="4"
        class="text-center"
      >
        <radial-bar class="pt-2" :score="score" :color="themeColors[color]" :priority="scenarioData.priority"></radial-bar>
        <span class="text-muted">
          Priority
        </span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import { avatarText } from '@core/utils/filter'
import radialBar from './radialScenario.vue'
import useApplicationsList from '../api/useApplicationsList'

export default {
  components: {
    BCard, BButton, BRow, BCol, radialBar,
  },
  props: {
    scenarioData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    score() {
      if (this.scenarioData.priority === 'High') return 100
      if (this.scenarioData.priority === 'Medium') return 50
      return 25
    },
    color() {
      if (this.scenarioData.priority === 'High') return 'danger'
      if (this.scenarioData.priority === 'Medium') return 'warning'
      return 'info'
    },
  },
  data() {
    return {
      themeColors: $themeColors,
    }
  },
  setup() {
    const { resolveApplicationRoleVariant } = useApplicationsList()
    return {
      avatarText,
      resolveApplicationRoleVariant,
    }
  },
}
</script>

<style>

</style>
