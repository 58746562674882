<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-row class="mb-2">
            <b-col cols="12" style="font-size:24px;">
              <h4>Results</h4>
              <b-badge
                  v-if="scenarioData.failed"
                  pill
                  variant="light-danger"
                  class="text-capitalize"
                >
                  Failed: {{ scenarioData.error }}
                </b-badge>
                <b-badge
                  v-else
                  pill
                  variant="light-success"
                  class="text-capitalize"
                >
                  Passed: {{ scenarioData.success }}
              </b-badge>
              <div class="float-right">
                <router-link  :to="`/action/${scenarioData.fix}_${scenarioData.app_name}`" v-if="selectedScenario.failed && scenarioData.fix" v-b-modal.model-fix  class="btn btn-success">
                  Fix Now
                </router-link>
              </div>
            </b-col>
          </b-row>
          <div class="p-3" v-if="nodes.length > 1">
              <FlowChart :nodes="nodes" class="border-0 mt-4 svg-nobackground" width="100%" :height="100" :connections="connections"></FlowChart>
          </div>
          <b-row>
            <b-col cols="12">
              <div v-show="imgShow" class="mb-2">
                <h3 class="ml-1" >Screenshot</h3>
                <img @load="imgShow=true" :src="`https://static.saasment.com/${selectedScenario.org}/${selectedScenario.evidence_id}.png`" />
              </div>
            </b-col>
            <b-col cols="12" v-if="selectedScenario.resources.length > 0">
              <h4>Resources</h4>
              <b-table
                class="position-relative border-bottom"
                :items="selectedScenario.resources"
                :fields="resourcesFields"
                responsive
                show-empty>
                <template #cell(name)="data">
                  <img :src="data.item.type">{{" " + data.item.name}}
                </template>
                <template #cell(permissions)="data">
                  <div v-for="permission in data.item.permissions" :key="permission">
                    {{ permission }}
                  </div>
                </template>
                <template #cell(path)="data">
                  <span v-if="data.item.path && data.item.path.startsWith('http')">
                    <a target="_blank" :href="data.item.path">link</a>
                  </span>
                  <span v-else>
                    {{data.item.path}}
                  </span>
                </template>
                <template #cell(raw)="data">
                  <a v-b-modal="`modal-${data.item.name}`">view</a>
                  <b-modal
                    hide-footer
                    hide-header
                    size="lg"
                    :id="`modal-${data.item.name}`"
                    >
                    <json-viewer :value="data.item.raw"></json-viewer>
                  </b-modal>
                </template>
                <template #cell(users)="data">
                  <a v-b-modal="`modal-users-${data.item.name}`">{{ data.item.users.length }} users</a>
                  <b-modal
                    hide-footer
                    hide-header
                    size="lg"
                    :id="`modal-users-${data.item.name}`"
                    >
                    <div v-for="user in data.item.users" :key="user">
                    {{ user }}
                    </div>
                  </b-modal>
                </template>
              </b-table>
            </b-col>
            <b-col cols="12" v-if="selectedScenario.actors.length > 0">
              <h4>Identities</h4>
              <b-table
                class="position-relative"
                :items="selectedScenario.actors"
                :fields="actorsFields"
                responsive
                show-empty>
                <template #cell(name)="data">
                  <feather-icon
                    icon="UserIcon"
                  /> {{data.item.name}}
                </template>
                <template #cell(groups)="data">
                  {{data.item.groups.join(", ")}}
                </template>
                <template #cell(lastLogin)="data">
                  {{ new Date(data.item.lastLogin).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}
                </template>
                <template #cell(raw)="data">
                  <a v-b-modal="`modal-${data.item.name}`">view</a>
                  <b-modal
                    hide-footer
                    hide-header
                    size="lg"
                    :id="`modal-${data.item.name}`"
                    >
                    <json-viewer :value="showJsonViewer(data.item.raw)"></json-viewer>
                  </b-modal>
                </template>
              </b-table>
            </b-col>
            <b-col cols="12" v-if="selectedScenario.configurations.length > 0">
              <h4>Configurations</h4>
              <b-table
                class="position-relative border-right"
                :items="selectedScenario.configurations"
                :fields="configurationsFields"
                responsive
                show-empty>
                  <template #cell(raw)="data">
                    <a v-b-modal="`modal-${data.item.name}`">view</a>
                    <b-modal
                      hide-footer
                      hide-header
                      size="lg"
                      :id="`modal-${data.item.name}`"
                      >
                      <json-viewer :value="data.item.raw"></json-viewer>
                    </b-modal>
                  </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import FlowChart from 'flowchart-vue'
import {
  BCard, BTable, BRow, BCol, BCardBody, BModal, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useApplicationsList from '../api/useApplicationsList'

export default {
  components: {
    BCard, BTable, BRow, BCol, FlowChart, BCardBody, BModal, BBadge,
  },
  data() {
    return {
      storyField: [
        { key: 'application', sortable: true },
        { key: 'lastCheck', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actors', sortable: true },
        { key: 'resources', sortable: true },
      ],
      storyFieldMin: [
        { key: 'application', sortable: true },
        { key: 'status', sortable: true },
      ],
      storyFieldShow: [],
      showScenarioFlag: false,
      imgShow: false,
      nodes: [],
      connections: [],
      selectedApplication: null,
    }
  },
  props: {
    scenarioData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getApps() {
      const apps = []
      Object.keys(this.scenarioData.applications).forEach(element => {
        apps.push({ name: element })
      })
      return apps
    },
    selectedScenario() {
      return this.scenarioData
    },
    actorsFields() {
      return this.selectedScenario.actorsFields.map(x => ({ key: x }))
    },
    configurationsFields() {
      return this.selectedScenario.configurationsFields.map(x => ({ key: x }))
    },
    resourcesFields() {
      return this.selectedScenario.resourcesFields.map(x => ({ key: x }))
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return ''
      }
      if (item.application === this.selectedApplication) {
        return 'selectedRow'
      }
      return ''
    },
    hideScenario() {
      this.showScenarioFlag = false
      this.storyFieldShow = this.storyField
    },
    showScenario(record) {
      this.showScenarioFlag = true
      this.selectedApplication = record.name
      this.storyFieldShow = this.storyFieldMin
      if (Object.values(this.scenarioData.applications[record.name]).length) {
        this.nodes = this.getNodes(this.scenarioData.applications[record.name].story)
        this.connections = this.getConnections(this.scenarioData.applications[record.name].story)
      } else {
        this.nodes = []
        this.connections = []
      }
    },
    getNodes(data) {
      const nodes = []
      let index = 2
      let i = 0
      nodes.push({
        id: 1,
        x: 0,
        y: 40,
        width: 20,
        height: 20,
        name: '',
        type: 'start',
      })
      for (i = 0; i < data.length; i += 1) {
        const tmp = data[i]
        if (tmp.approvers[0].name.length > tmp.name.length) {
          tmp.width = tmp.approvers[0].name.length * 10
        } else {
          tmp.width = tmp.name.length * 10
        }
        tmp.id = index
        tmp.x = nodes[i].x + nodes[i].width + 30
        tmp.y = 20
        nodes.push(tmp)
        index += 1
      }

      return nodes
    },
    getConnections(data) {
      const connections = []
      let index = 1
      data.forEach(element => {
        connections.push({
          source: { id: index, position: 'right' },
          destination: { id: index + 1, position: 'left' },
          id: index,
          type: element.failed ? 'reject' : 'pass',
        })
        index += 1
      })
      return connections
    },
  },
  mounted() {
    this.storyFieldShow = this.storyField
  },
  setup() {
    const { resolveApplicationRoleVariant } = useApplicationsList()
    return {
      avatarText,
      resolveApplicationRoleVariant,
    }
  },
}
</script>

<style>
.svg-nobackground svg {
  background-image: none!important;
}
.selectedRow {
  background-color: rgba(248, 248, 248, 1);
  border-width: 0px;
}
.dark-layout .selectedRow {
  background-color: #161d31;
}

</style>
