<template>
  <vue-apex-charts type="radialBar" :options="chartOptions" :series="series" height="300"></vue-apex-charts>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

const $strokeColor = '#ebe9f1'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    priority: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      series: [this.score],
      chartOptions: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [this.color],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -90,
            endAngle: 90,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: '1.86rem',
              },
              value: {
                show: false,
                color: this.color,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [this.color],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
        labels: [this.priority],
      },
    }
  },
}
</script>

<style>

</style>
