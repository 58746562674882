<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="scenarioData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching scenario data
      </h4>
      <div class="alert-body">
        No scenario found with this scenario id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-scenarios-list'}"
        >
          Scenario List
        </b-link>
        for other scenarios.
      </div>
    </b-alert>

    <template v-if="scenarioData != null">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <scenario-view-scenario-info-card :scenario-data="scenarioData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <!-- <scenario-view-scenario-plan-card :scenario-data="scenarioData" /> -->
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <scenario-view-scenario-service-story-card :scenario-data="scenarioData" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <!-- <scenario-view-scenario-permissions-card :scenario-data="scenarioData" /> -->
        </b-col>
      </b-row>

      <!-- <scenarios :query="scenarioData.name" /> -->
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
// import Scenarios from '@/views/apps/user/users-list/Scenarios.vue'
import scenarioStoreModule from '../api/scenarioStoreModule'
import ScenarioViewScenarioInfoCard from './ScenarioViewScenarioInfoCard.vue'
import ScenarioViewScenarioServiceStoryCard from './ScenarioViewScenarioServiceStoryCard.vue'
// import ScenarioViewScenarioPermissionsCard from './ScenarioViewScenarioPermissionsCard.vue'
// import ScenarioViewScenarioPermissionsCardExternal from './ScenarioViewScenarioPermissionsCardExternal.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    ScenarioViewScenarioInfoCard,
    ScenarioViewScenarioServiceStoryCard,
    // ScenarioViewScenarioPermissionsCardExternal,
    // ScenarioViewScenarioPermissionsCard,

    // Scenarios,
  },
  setup() {
    const scenarioData = ref(null)

    const SCENARIO_APP_STORE_MODULE_NAME = 'app-scenario'

    // Register module
    if (!store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.registerModule(SCENARIO_APP_STORE_MODULE_NAME, scenarioStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.unregisterModule(SCENARIO_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-scenario/fetchScenario', { id: router.currentRoute.params.id })
      .then(response => {
        scenarioData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          scenarioData.value = undefined
        }
      })

    return {
      scenarioData,
    }
  },
}
</script>

<style>

</style>
